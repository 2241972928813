<template>
  <div class="end_block">
    <div class="col-12 col-xl-11 offset-xl-1">
      <p class="documents-rgpd-disclaimer text-start ">
        <i>Cet espace est totalement sécurisé et vos documents ne seront utilisés
          que pour
          la constitution de votre dossier de financement auprès de nos organismes
          prêteurs.</i>
      </p>
    </div>
  </div>
</template>

<style scoped>
.documents-rgpd-disclaimer {
  font-size: 12px;
  color: #707070;
}

@media only screen and (min-device-width: 768px) {
  .end_block {
    margin-left: 1rem;
  }
}
</style>